import React, { useEffect, useState } from "react";
import Loadable from "@loadable/component";
import Cookies from "js-cookie";
import $ from "jquery";
import axios from "../../utils/request";
import style from "./header.module.css";

const LoginBox = Loadable(() => import("../loginbox/index"));

// let scrolling = false;
export default function MainHeader() {
  const [active, setActive] = useState(false);
  const [showIcyIcon, setShowIcyIcon] = useState(false);
  const [show, setShow] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [showLoginBox, setShowLoginBox] = useState(false);
  const [winWidth, setWinWidth] = useState(0);
  const [mounted, setMounted] = useState(false);

  function handleHeaderColor() {
    let heightThreshold = $("#milestone").offset().top;
    let heightThreshold_end =
      $("#designer").offset().top + $("#designer").outerHeight();
    let scroll = $(window).scrollTop();

    let actives =
      scroll >= heightThreshold && scroll <= heightThreshold_end - 1;
    setActive(actives);

    setShowIcyIcon(scroll > window.innerHeight);
  }

  function handelBoxShow() {
    setShowBox(true);
  }

  function handelBoxHide() {
    setShowBox(false);
  }

  async function handleIsLogin() {
    let token = Cookies.get("token");
    // console.log(token);
    if (!token) {
      setShowLoginBox(true);
      return;
    }
    try {
      const { data } = await axios({
        method: "get",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        url: "/designer/userinfo",
      });
      if (
        data.status === 0 ||
        (data.status === 1 && data.contractStatus === 9) ||
        data.status === 2
      ) {
        window.open("/designerPress/verify", "_blank");
        // navigateTo("/designerPress/verify");
      }
      if (data.contractStatus === 0) {
        window.open("/designerPress/contract", "_blank");
        // navigateTo("/designerPress/contract");
      }
      if (
        data.contractStatus === 1 && data.jointInfoStatus === 0
      ) {
        window.open("/designerPress/jointInfo", "_blank");
        // navigateTo("/designerPress/jointInfo");
      }
      if (
        data.contractStatus === 1 &&
        data.profileStatus === 0 && data.jointInfoStatus === 1
      ) {
        window.open("/designerPress/fullInfo", "_blank");
        // navigateTo("/designerPress/fullInfo");
      }
      if (data.profileStatus === 1) {
        window.open("/designerPress/designerIndex", "_blank");
        // navigateTo("/designerPress/designerIndex");
      }
    } catch (err) {
      setShowLoginBox(true);
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleHeaderColor);

    $("a[href*=\"#\"]").click(function () {
      let target = $(this.hash);
      console.log(target);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: target.offset().top,
          },
          800
        );
        setShow(false);
      }
      return false;
    });

    $("body").mousemove(function (event) {
      let w = $(this).width(),
        pct = (360 * +event.pageX) / w;
      $("#topLogo").css("transform", "rotate(" + pct + "deg)");
    });

    // $("body").click(function (event) {
    //   if (event.target.id !== "pulisher" && event.target.id !== "cooperate") {
    //     setShowBox(false);
    //   }
    // });

    setWinWidth($(window).width());

    setMounted(true);

    return () => {
      document.removeEventListener("scroll", handleHeaderColor);
    };
  }, []);

  return (
    <div
      id="a-00"
      className={`${style.header} fixed-top ${active ? style.dark : ""}`}
      style={{ display: mounted ? "block" : "none" }}
    >
      <div className={`container-fluid p-0 ${style.headerWrap}`}>
        {!show && (
          <div className={style.logo}>
            <a className="link anchor ctn-atractive" href="#start">
              <div className="contact-area">
                <div className="text-hover">
                  {showIcyIcon && (
                    <div className={`topLogo ${style.icyIcon}`}></div>
                  )}
                  {!showIcyIcon && (
                    <div
                      id="topLogo"
                      className={`topLogo ${style.navigateIcon}`}
                    ></div>
                  )}
                </div>
              </div>
            </a>
          </div>
        )}
        <div className={style.menuBtn} onClick={() => setShow(!show)}>
          <div
            className={`${style.icon} ${show ? style.iconEffect : ""}`}
          ></div>
        </div>
        <div
          className={`${style.menuWrap} ${
            show ? style.menuDown : style.menuUp
          }`}
        >
          <div className={`${style.menuList} row justify-content-end`}>
            <div className="col-lg-11 col-sm-12">
              <ul className={`${style.mobileNav} ${style.pcNav} navbar`}>
                <li style={{ display: winWidth <= 576 ? "block" : "none" }}>
                  <div className={`${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#start"
                      >
                        首页
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`contact-area ${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#introduction"
                      >
                        品牌介绍
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`contact-area ${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#work"
                      >
                        品牌事件
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`contact-area ${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#designer"
                      >
                        设计师联盟
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`contact-area ${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#kolstar"
                      >
                        KOL&STAR
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`contact-area ${style.mobileArea}`}>
                    <div className="text-hover">
                      <a
                        className={`anchor ctn-atractive ${style.link}`}
                        href="#findus"
                      >
                        找到我们
                      </a>
                    </div>
                  </div>
                </li>
                <li
                  onMouseEnter={handelBoxShow}
                  onMouseLeave={handelBoxHide}
                  // onClick={handelBoxShow}
                  style={{
                    position: "relative",
                    padding: "1.3889vw 0px 1.3889vw 3.125vw",
                    marginLeft: "0px",
                    display: winWidth > 576 ? "block" : "none",
                  }}
                  className={style.publisher}
                  id="publisher"
                >
                  <span
                    className={`${style.publisherWord} ${
                      showBox ? style.wordActive : ""
                    }`}
                    id="publiser"
                  >
                    产品出版社
                  </span>
                  {showBox && (
                    <div className={style.loginBox} id="publiserBox">
                      <img
                        width="640"
                        height="360"
                        src={require("../../img/arrow_top.png")}
                        alt="icy产品出版社"
                      />
                      <div className={style.headerWord}>
                        <h3>
                          ICY作为全球设计师产品“出版社”，至今已合作400+全球设计师，累计出版4000+全球好设计
                        </h3>
                        <h3>想让你的好设计遇见更多人吗？赶快加入我们吧～</h3>
                      </div>
                      <span
                        className={style.cooperateButton}
                        onClick={handleIsLogin}
                        id="cooperate"
                      >
                        开始合作
                      </span>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <LoginBox isShowLogin={showLoginBox} hideFn={setShowLoginBox}></LoginBox>
    </div>
  );
}

export const city = ['全部城市', '上海', '成都', '广州', '深圳'];

export const shop = {
  '上海': [
    {
      name: 'ICY上海来福士人民广场店',
      address: '上海市人民广场来福士广场3层L3-12B',
      tel: '021-53832239',
      city: '上海',
    },
    {
      name: 'ICY上海环贸iapm商场店',
      address: '上海市环贸iapm商场LG1-179',
      tel: '021-61912517',
      city: '上海',
    },
    {
      name: 'ICY上海七宝万科广场店',
      address: '上海市闵行区漕宝路3366号七宝万科广场2楼L233A商铺',
      tel: '',
      city: '上海',
    }
  ],
  '成都': [
    {
      name: 'ICY成都万象城店',
      address: '成都市成华区万象城B馆2楼225号',
      tel: '028-85970117',
      city: '成都',
    },
  ],
  '广州': [
    {
      name: 'ICY广州天汇广场店',
      address: '广州市天河区天汇广场B123号',
      tel: '',
      city: '广州',
    },
    {
      name: 'ICY广州天河城店',
      address: '广州市天河区天河城2F-267号',
      tel: '',
      city: '广州',
    },
  ],
  '深圳': [
    {
      name: 'ICY深圳万象天地店',
      address: '深圳市南山区粤海街道大冲社区深南大道9868号华润万象天地SL355号',
      tel: '',
      city: '深圳',
    },
    {
      name: 'ICY深圳海雅缤纷城店',
      address: '深圳市宝安区宝城5区宝民路海雅缤纷城第叁层 L303-L304号 110',
      tel: '',
      city: '深圳',
    },
    {
      name: 'ICY深圳KKmall店',
      address: '深圳市罗湖区桂园街道深南东路5016号322+323号',
      tel: '',
      city: '深圳',
    },
  ]
}

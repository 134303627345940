import React from "react";
import style from "./slidethree.module.css";
import { mileData } from "../../meta/mileStone";

export default function SlideThree() {
  return (
    <div className={`${style.slideOne} d-flex`} id="milestone" data-id="page">
      <div className={`container-fluid ${style.milestoneWrap}`}>
        <div className="row h-100 align-items-center m-0 justify-content-end">
          <div className={style.slideOneContent}>
            <ul className={style.milestone}>
              {Object.keys(mileData)
                .reverse()
                .map((key) => (
                  <li key={key}>
                    <span className={style.milestoneLeft}>
                      {mileData[key].map((item, i) => (
                        <span key={i}>
                          {item}
                          {i !== mileData[key].length - 1 && <span>/</span>}
                        </span>
                      ))}
                    </span>
                    <span className={style.milestoneRight}>{key}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import $ from "jquery";
import Loadable from "@loadable/component";
import Cursor from "../components/cursor";
import MainHeader from "../components/header";
import SlideOne from "../components/slideone";
import SlideTwo from "../components/slidetwo";
import SlideThree from "../components/slidethree";
import Designer from "../components/designer";
import KolStar from "../components/kolstar";
import FindUs from "../components/findus";
import style from "./index.module.css";
import "rc-message/assets/index.css";
// import useFullpage from "../hook/useFullpage";

const BrandEvent = Loadable(() => import("../components/brandevent"));
// const windowWidth =
//   typeof window !== "undefined" ? document.documentElement.clientWidth : 0;
let index = 0;

function findDataId(node, result, e) {
  // console.warn(node.parentNode);
  if (!node || node === document.body || result.length) return;
  if (e.deltaY > 0) {
    if (node.id === "milestone") node.removeAttribute("data-id");
    if (node.id === "designer") node.setAttribute("data-id", "page");
  } else {
    if (node.id === "designer") node.removeAttribute("data-id");
    if (node.id === "milestone") node.setAttribute("data-id", "page");
  }
  if (node.getAttribute("data-id") === "page") result.push(true);
  // console.log(node.getAttribute("data-id"));
  findDataId(node.parentNode, result, e);
}

function scrolling(action) {
  const offsetTopList = $.map(
    $("[data-id=\"page\"]"),
    (node) => $(node).offset().top
  );
  const targetIndex = action === "up" ? index - 1 : index + 1;
  if (targetIndex > -1 && targetIndex < offsetTopList.length) {
    $("html, body").animate({ scrollTop: offsetTopList[targetIndex] }, 800);
    index = targetIndex;
  }
}

function handleMouseWheel(e) {
  const { target } = e;
  let result = [];
  findDataId(target, result, e);
  if (!result.length) return;
  scrolling(e.deltaY > 0 ? "down" : "up");

  document.removeEventListener("wheel", handleMouseWheel);
  setTimeout(() => {
    document.addEventListener("wheel", handleMouseWheel);
  }, 1000);
}

export default function Home() {
  // 全屏滚动
  // useFullpage();

  useEffect(() => {
    // if(windowWidth > 576){
    //   document.addEventListener('wheel', handleMouseWheel)
    // }

    return () => {
      document.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  return (
    <div className="page">
      <Cursor></Cursor>
      <div className={style.content}>
        {/* 菜单 */}
        <MainHeader></MainHeader>
        {/* 首屏 */}
        <SlideOne></SlideOne>
        {/* 标语 */}
        <SlideTwo></SlideTwo>
        {/* 里程碑 */}
        <SlideThree></SlideThree>
        {/* 品牌事件 */}
        <BrandEvent></BrandEvent>
        {/* 设计师联盟 */}
        <Designer></Designer>
        {/* KOL&STAR */}
        <KolStar></KolStar>
        <FindUs></FindUs>
      </div>
    </div>
  );
}

import React from "react";
import style from "./slideone.module.css";

export default function SlideOne() {
  return (
    <div id="start" className={`${style.slideOne} d-flex`}>
      <div className="container-fluid">
        <div className="row height-100 align-items-center m-0">
          <div className={style.slideOneContent}>
            <p className={style.brandTitle}>
              CHOOSE YOUR LOVE AND LOVE YOUR CHOICE
              <span className={style.revealLine}></span>
            </p>
            <div className={style.wechatQR}>
              <img
                width="640"
                height="360"
                className={"lazyload"}
                data-src="https://image3.ichuanyi.cn/Files/88/f3/c4/88f3c403cf112fc6e2d14a453b982a73d3c95ffa_82204.png"
                alt="icy公众号"
              />
            </div>
          </div>
        </div>
      </div>
      <img
        width="640"
        height="360"
        className={`lazyload ${style.icyLogo}`}
        data-src={
          "https://image3.ichuanyi.cn/Files/2f/c4/a1/2fc4a19bbb8dbdcf6b5650422fa2ca076bbed16f_35102.png"
        }
        alt="icy logo"
      />
    </div>
  );
}

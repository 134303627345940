import React from "react";
import { Link } from "gatsby";
import style from "./kolstar.module.css";

export default function KolStar() {
  return (
    <div data-id="page" id="kolstar" className={`${style.slideOne} d-flex`}>
      <div className="row h-100 align-items-center m-0">
        <div className={style.kolWrap}>
          <div className={style.kolWrapLeft}>
            <div className={style.kolLeftImg}></div>
            <h3>明星演绎&博主穿搭</h3>
            <div className={style.seeMore}>
              <Link to="/blogger">
                <h4>
                  查看更多
                  <div className={style.moreIcon} />
                </h4>
              </Link>
            </div>
          </div>
          <div className={style.kolWrapRight}>
            <div className={style.kolRightImageOne} />
            <div className={style.kolRightImageTwo} />
          </div>
        </div>
      </div>
    </div>
  );
}

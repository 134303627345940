const IOS_URL = "http://a.app.qq.com/o/simple.jsp?pkgname=com.ichuanyi.icy";
const ANDROID_URL = "http://a.app.qq.com/o/simple.jsp?pkgname=com.ichuanyi.icy";

export function downloadApp() {
  let d = new Date();
  let t0 = d.getTime();
  // 判断是安卓还是ios

  if (isAndroid()) {
    if (isWeiXin()) {
      // 引导用户在浏览器中打开
      alert("请在浏览器中打开本链接");
      return;
    }
    //Android
    if (openApp("ydicy://designer_tab")) {
      this.openApp("ydicy://designer_tab");
    } else {
      //由于打开需要1～2秒，利用这个时间差来处理－－打开app后，返回h5页面会出现页面变成app下载页面，影响用户体验
      let delay = setInterval(function () {
        let d = new Date();
        let t1 = d.getTime();
        if (t1 - t0 < 3000 && t1 - t0 > 2000) {
          window.location.href = ANDROID_URL;
        }
        if (t1 - t0 >= 3000) {
          clearInterval(delay);
        }
      }, 1000);
    }
  } else {
    window.location.href = IOS_URL;
  }
}

export function isAndroid() {
  let u = navigator.userAgent;
  return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
}

export function isIOS() {
  let u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

export function isWeiXin() {
  return /MicroMessenger/gi.test(navigator.userAgent);
}

export function isPC() {
  if (isIOS() || isAndroid()) {
    return false;
  }
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export function openApp(src) {
  // 通过iframe的方式试图打开APP，如果能正常打开，会直接切换到APP，并自动阻止a标签的默认行为
  // 否则打开a标签的href链接
  let ifr = document.createElement("iframe");
  ifr.src = src;
  ifr.style.display = "none";
  document.body.appendChild(ifr);
  window.setTimeout(function () {
    document.body.removeChild(ifr);
  }, 2000);
}

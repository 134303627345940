import React, { useState, useEffect } from "react";
import $ from "jquery";
import { downloadApp, isPC } from "../../utils/downloadManager";
import { shop, city } from "../../meta/map";
import loadBMap from "../../utils/loadBMap";
import style from "./findus.module.less";

export default function FindUs() {
  const [winWidth, setWinWidth] = useState(0);
  const [current, setCurrent] = useState(0);
  const [position, setPosition] = useState("全部城市");
  const [BMap, setBMap] = useState(null);
  const [id, setId] = useState("");
  const [citySelectShow, setCitySelectShow] = useState(false);
  let map = "";

  function getQuery(query) {
    let url = decodeURI(window.location.href);
    if (url.indexOf("?") !== -1) {
      url = url.split("?")[1];
    }
    if (url) {
      let queryList = url.split("&");
      for (let i = 0; i < queryList.length; i++) {
        let srtArray = queryList[i].split("=");
        if (srtArray.length === 2 && srtArray[0] === query) {
          return srtArray[1];
        }
      }
    }
    return null;
  }

  function removeAllQuery() {
    let url = decodeURI(window.location.href);
    if (url.indexOf("?") !== -1) {
      window.location.href = url.split("?")[0];
    }
  }

  function checkDownload() {
    if (!isPC()) {
      downloadApp();
    }
  }

  function addHoverEventById(id, displayId) {
    $(id).hover(
      function () {
        $(displayId).css("display", "flex");
      },
      function () {
        $(displayId).css("display", "none");
      }
    );
  }

  function addClickEventById(id, callback) {
    $(id).one("click", function () {
      callback();
    });
  }

  function handleLeft() {
    let index = current;
    index = index > 0 ? index - 1 : 0;
    setCurrent(index);
    shopPosition(handleShopData()[index].city, handleShopData()[index].address, id);
  }

  function handleRight() {
    let index = current;
    index = index === handleShopData().length - 1 ? handleShopData().length - 1 : index + 1;
    setCurrent(index);
    shopPosition(handleShopData()[index].city, handleShopData()[index].address, id);
  }

  function handleCityClick(e) {
    setCurrent(0);
    setPosition(e);
    const firstShopAddr = handleShopData(e)?.[0]?.address;
    shopPosition(e, firstShopAddr, id);
    setCitySelectShow(false);
  }

  async function initialBMap() {
    await loadBMap("EHsxvqCKe7T6L7GcCW3o2dHKDrgtEcRb");
    if (window.BMap) {
      setBMap(window.BMap);
    }
  }

  function handleShopData(e = "") {
    let data = [];
    const city = e ? e : position;
    if (city === "全部城市") {
      Object.values(shop).forEach((item) => {
        data.push(...item);
      });
    } else {
      data = shop[city] || [];
    }

    return data;
  }

  function handleSwitchShop(item, index) {
    setCurrent(index);
    shopPosition(position, item.address, id);
  }

  function shopPosition(city, address, id) {
    map = new BMap.Map(id);
    let myGeo = new BMap.Geocoder();
    myGeo.getPoint(address, function(point){
      if (point) {
        map.centerAndZoom(point, 16);
        let myIcon = new BMap.Icon("https://image3.ichuanyi.cn/Files/8e/ea/8c/8eea8ce2081ab09c6dba51777e687a8c77df9694_3722.png", new BMap.Size(60, 48), {
          anchor: new BMap.Size(10, 25),
        });
        let marker = new BMap.Marker(point, {icon: myIcon});
        map.addOverlay(marker);
        map.setMapStyleV2({
          styleId: "c3955ed2fe1199736b8092689f2337ec"
        });
      }
    },
    city);
  }

  useEffect(() => {
    if (getQuery("download")) {
      downloadApp();
      window.setTimeout(function () {
        removeAllQuery();
      }, 3500);
    }
    if (isPC()) {
      addHoverEventById("#iosBox", "#appStoreQr");
      addHoverEventById("#androidBox", "#androidQr");
      addHoverEventById("#qrCode", "#weChatQr");
    } else {
      addClickEventById("#iosBox", checkDownload);
      addClickEventById("#androidBox", checkDownload);
      addClickEventById("#qrCode", checkDownload);
    }

    setWinWidth($(window).width());
    // 加载百度地图
    initialBMap();
    return () => {};
  }, []);

  useEffect(() => {
    if (winWidth > 576) {
      setId("map");
    } else {
      setId("mobileMap");
    }
  }, [winWidth]);

  useEffect(() => {
    if (BMap && id) {
      // 定位当前城市
      const currentCity = new BMap.LocalCity();
      currentCity.get((result) => {
        let cityName = result.name;
        if (cityName) cityName = cityName.slice(0, -1);
        if (Object.keys(shop).includes(cityName)) {
          setPosition(cityName);
          shopPosition(cityName, shop[cityName]?.[0]?.address, id);
        } else {
          setPosition("全部城市");
          const firstAll = handleShopData()?.[0];
          shopPosition(firstAll.city, firstAll.address, id);
        }
      });
    }
  }, [BMap, id]);

  return (
    <div className={style.findUs} id="findus" data-id="page">
      <div className={`${style.findLeft}`}>
        <h2 className={`${style.title} ${style.mobileTitle}`}>
          线下门店
          <div className={style.selectWrap} style={{ display: winWidth <= 576 ? "block" : "none" }}>
            <div className={style.wordShow} onClick={() => setCitySelectShow(true)}>
              <span>{position}</span>
              <img src={require("../../img/arrow_up.png")} alt="" width="16" height="16"/>
            </div>
            <ul className={style.cityList} style={{display: citySelectShow ? "block" : "none"}}>
              {
                city.map((e) => (
                  <li key={e} onClick={() => handleCityClick(e)}>{e}</li>
                ))
              }
            </ul>
          </div>
        </h2>
        <div
          className={style.underlineShop}
          style={{ display: winWidth <= 576 ? "block" : "none" }}
        >
          <div className={style.map} id="mobileMap"></div>
          <div className={style.shopInfo}>
            <i className={style.arrowLeft} onClick={handleLeft}>
              <img
                width="640"
                height="360"
                src={
                  current === 0
                    ? require("../../img/map_gleft.png")
                    : require("../../img/map_bleft.png")
                }
                alt="左箭头"
              />
            </i>
            <i className={style.arrowRight} onClick={handleRight}>
              <img
                width="640"
                height="360"
                src={
                  current === handleShopData().length - 1
                    ? require("../../img/map_gright.png")
                    : require("../../img/map_bright.png")
                }
                alt="右箭头"
              />
            </i>
            <ul>
              <li>
                <h3>{ handleShopData()[current].name }</h3>
                <p>{ handleShopData()[current].address }</p>
                <p>{ handleShopData()[current].tel }</p>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={style.underlineShop}
          style={{ display: winWidth > 576 ? "flex" : "none" }}
        >
          <div className={style.map} id="map"></div>
          <div className={style.shopInfo}>
            <div className={style.selectWrap}>
              <div className={style.wordShow}>
                <img src={require("../../img/position.png")} alt="" width="16" height="16"/>
                <span>{position}</span>
                <img src={require("../../img/arrow_up.png")} alt="" width="16" height="16"/>
              </div>
              <ul className={style.cityList}>
                {
                  city.map((e) => (
                    <li key={e} onClick={() => handleCityClick(e)}>{e}</li>
                  ))
                }
              </ul>
            </div>
            <ul>
              {
                handleShopData().map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSwitchShop(item, index)}
                    style={{
                      color:
                        current === index ? "rgba(0,0,0,1)" : "rgba(0,0,0,0.5)",
                    }}
                  >
                    <h3>{ item.name }</h3>
                    <p>{ item.address }</p>
                    <p>{ item.tel }</p>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <div className={`${style.findRight}`}>
        <div>
          <h2 className={`${style.title} ${style.mobileTitleFind}`}>
            找到我们
          </h2>
          <div className={style.findWay}>
            <ul className={style.downloadList}>
              {winWidth <= 576 && (
                <li className={style.mobileCode}>
                  <img
                    width="640"
                    height="360"
                    className={"lazyload"}
                    data-src="https://image3.ichuanyi.cn/Files/88/f3/c4/88f3c403cf112fc6e2d14a453b982a73d3c95ffa_82204.png"
                    alt="icy公众号，ICY公众号"
                  />
                </li>
              )}
              <li onClick={checkDownload} className={style.iosBox} id="iosBox">
                <img
                  width="640"
                  height="360"
                  src={require("../../img/apple.png")}
                  alt="icy苹果应用程序"
                />
                <div className={style.appStoreQr} id="appStoreQr">
                  <div className={style.qrCodeBg} />
                  <span>扫码下载ICY APP</span>
                </div>
              </li>
              <li
                onClick={checkDownload}
                className={style.androidBox}
                id="androidBox"
              >
                <img
                  width="640"
                  height="360"
                  src={require("../../img/android.png")}
                  alt="icy安卓应用程序"
                />
                <div className={style.androidQr} id="androidQr">
                  <div className={style.qrCodeBg} />
                  <span>扫码下载ICY APP</span>
                </div>
              </li>
              <li
                style={{ display: winWidth > 576 ? "block" : "none" }}
                className={style.qrCode}
                id="qrCode"
              >
                <img
                  width="640"
                  height="360"
                  src={require("../../img/wechat.png")}
                  alt="icy公众号"
                />
                <div className={style.weChatQr} id="weChatQr">
                  <div className={style.qrCodeBg} />
                  <span>扫码关注ICY公众号</span>
                </div>
              </li>
            </ul>
            <div className={style.findUsContact}></div>
          </div>
        </div>
        <div>
          <h2 className={style.title}>联络</h2>
          <a href="mailto:bd@yourdream.cc" className={style.contactMail}>
            bd@yourdream.cc
          </a>
        </div>
      </div>

      <div className={style.footer}>
        <p>Copyright © 2018-2021</p>
        <a
          className={style.link}
          href="https://beian.miit.gov.cn"
          target="__blank">沪ICP备2023040158号-1</a>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "gatsby";
import style from "./designer.module.css";

export default function Design() {
  return (
    <div data-id="page" id="designer">
      <div className={style.designer}>
        <div className={style.rotateCircle}></div>
        <div className={style.rotateText}>
          <span className={`text-hover ${style.arrowBg}`}>
            <Link
              to="/designerLeague/"
              style={{
                display: "inline-block",
                width: "100%",
                height: "100%",
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

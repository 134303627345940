import React from "react";
import style from "./slidetwo.module.css";

export default function SlideOne() {
  return (
    <div className={`${style.slideOne} d-flex`} data-id="page">
      <div className="container-fluid">
        <div className="row h-100 align-items-center m-0">
          <div className={style.slideOneContent}>
            <ul className={style.heart}>
              <li>
                <span className={style.heartLeft}>MISSION</span>
                <span className={style.heartRight}>让好的设计遇见对的人</span>
              </li>
              <li>
                <span className={style.heartLeft}>VISION</span>
                <span className={style.heartRight}>
                  成为全球设计师的「产品出版社」
                </span>
              </li>
              <li>
                <span className={style.heartLeft}>VALUE</span>
                <span className={style.heartRight}>服装是自我表达的第一步</span>
              </li>
            </ul>
            <div className={style.about} id="introduction">
              <img
                width="640"
                height="360"
                className={style.leftDot}
                src={require("../../img/left_dot.png")}
                alt="左引号"
              />
              <img
                width="640"
                height="360"
                className={style.rightDot}
                src={require("../../img/right_dot.png")}
                alt="右引号"
              />
              <ul>
                <li>
                  ICY作为全球设计师“产品出版社”，致力于让好的设计遇见对的人，旨在为中国新一代独立女性提供展示个性的设计师品牌产品。ICY希望在时尚圈创建一个良性的设计生态，通过数据化的柔性供应链系统，提供更具性价比的设计师产品，把好设计变成好产品；并打通线上线下多元化的零售场景，将好产品变成好商品；通过ICY全球博主营销体系，让设计师产品被大众消费者所熟知。平台成立至今已累积合作400+全球独立设计师，落地4000+平价联名系列，通过2000+全球明星博主演绎，为超过150W的用户提供多元化独立时装。
                </li>
                <li>
                  2020年ICY线下品牌体验空间店陆续开启，通过打造多设计师，多风格的“容器感”品牌空间，开创集零售、体验、交流、情感为一体的购物场景新体验时代。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={style.nextStep}>
        里程碑
        <div className={style.iconArrow} />
      </div>
    </div>
  );
}
